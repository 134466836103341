import { render, staticRenderFns } from "./default.vue?vue&type=template&id=b72233d2"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipToContent: require('/src/nuxt-app/components/SkipToContent.vue').default,GlobalMessage: require('/src/nuxt-app/components/global-message/GlobalMessage.vue').default,SiteHeader: require('/src/nuxt-app/components/site-header/SiteHeader.vue').default,Breadcrumbs: require('/src/nuxt-app/components/Breadcrumbs.vue').default,SiteReviews: require('/src/nuxt-app/components/SiteReviews.vue').default,SiteFooter: require('/src/nuxt-app/components/site-footer/SiteFooter.vue').default,ToastNotifications: require('/src/nuxt-app/components/toast-notifications/ToastNotifications.vue').default,SiteMenu: require('/src/nuxt-app/components/site-header/mobile-menu/SiteMenu.vue').default})
