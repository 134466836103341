import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

/* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
const DISABLED_INTEGRATION_KEYS = []

export function getConfig (ctx) {
  const config = {
    dsn:"https:\u002F\u002F83b600ea132642cb90aa1c194c491e1f@o455520.ingest.sentry.io\u002F6725826",
    environment:"production",
    publishRelease:{"authToken":"cbca1d35fe1847b089b7d9c8ffc1a21beb8ad445ec834a49a3a9628ca83917de","org":"veken-kaluste-oy","project":"veke-veke3000","setCommits":{"auto":true,"ignoreMissing":true},"include":".\u002Fnuxt","ignore":["node_modules","webpack.config.js"]},
    release:"veke-veke3000@1.2.20",
    tracesSampleRate:0.1,
  }

  const resolvedIntegrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":0.1,"browserTracing":{},"vueOptions":{"trackComponents":true},"vueRouterInstrumentationOptions":{"routeLabel":"name"}}
  resolvedIntegrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
