import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5287695a = () => interopDefault(import('../pages/asiakaspalvelu/index.vue' /* webpackChunkName: "pages/asiakaspalvelu/index" */))
const _f307424a = () => interopDefault(import('../pages/asiakastilin-edut.vue' /* webpackChunkName: "pages/asiakastilin-edut" */))
const _428ff4ba = () => interopDefault(import('../pages/blogi/index.vue' /* webpackChunkName: "pages/blogi/index" */))
const _7a55c4e4 = () => interopDefault(import('../pages/brandit/index.vue' /* webpackChunkName: "pages/brandit/index" */))
const _4d0b14b8 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _5f1bc255 = () => interopDefault(import('../pages/content/index.vue' /* webpackChunkName: "pages/content/index" */))
const _1833ebcc = () => interopDefault(import('../pages/haku.vue' /* webpackChunkName: "pages/haku" */))
const _3ac574e0 = () => interopDefault(import('../pages/jaettu-ostoskori.vue' /* webpackChunkName: "pages/jaettu-ostoskori" */))
const _27c2adf8 = () => interopDefault(import('../pages/kassa.vue' /* webpackChunkName: "pages/kassa" */))
const _4f12f77c = () => interopDefault(import('../pages/keskenerainen-ostoskori.vue' /* webpackChunkName: "pages/keskenerainen-ostoskori" */))
const _34ae632c = () => interopDefault(import('../pages/keskusvarastot/index.vue' /* webpackChunkName: "pages/keskusvarastot/index" */))
const _7b83e3e4 = () => interopDefault(import('../pages/kiitos.vue' /* webpackChunkName: "pages/kiitos" */))
const _31f80c80 = () => interopDefault(import('../pages/kilpailut-ja-arvonnat.vue' /* webpackChunkName: "pages/kilpailut-ja-arvonnat" */))
const _e512795c = () => interopDefault(import('../pages/kirjaudu.vue' /* webpackChunkName: "pages/kirjaudu" */))
const _02148a1a = () => interopDefault(import('../pages/liveshopping-kiitos-ilmoituksesta.vue' /* webpackChunkName: "pages/liveshopping-kiitos-ilmoituksesta" */))
const _b2c188e0 = () => interopDefault(import('../pages/luo-tili.vue' /* webpackChunkName: "pages/luo-tili" */))
const _3f601b18 = () => interopDefault(import('../pages/myymalat/index.vue' /* webpackChunkName: "pages/myymalat/index" */))
const _8aeb341a = () => interopDefault(import('../pages/oma-tili.vue' /* webpackChunkName: "pages/oma-tili" */))
const _4ac16a36 = () => interopDefault(import('../pages/oma-tili/index.vue' /* webpackChunkName: "pages/oma-tili/index" */))
const _c7125844 = () => interopDefault(import('../pages/oma-tili/markkinointiluvat.vue' /* webpackChunkName: "pages/oma-tili/markkinointiluvat" */))
const _ea74374e = () => interopDefault(import('../pages/oma-tili/omat-tiedot.vue' /* webpackChunkName: "pages/oma-tili/omat-tiedot" */))
const _f2d4b9ee = () => interopDefault(import('../pages/oma-tili/tilaukset/index.vue' /* webpackChunkName: "pages/oma-tili/tilaukset/index" */))
const _c8e55916 = () => interopDefault(import('../pages/oma-tili/toivelistat/index.vue' /* webpackChunkName: "pages/oma-tili/toivelistat/index" */))
const _46ab8c1f = () => interopDefault(import('../pages/oma-tili/yhteenveto.vue' /* webpackChunkName: "pages/oma-tili/yhteenveto" */))
const _7e05ca64 = () => interopDefault(import('../pages/oma-tili/tilaukset/tilaus/index.vue' /* webpackChunkName: "pages/oma-tili/tilaukset/tilaus/index" */))
const _7c4f291c = () => interopDefault(import('../pages/oma-tili/tilaukset/tilaus/_slug.vue' /* webpackChunkName: "pages/oma-tili/tilaukset/tilaus/_slug" */))
const _dc58a1c6 = () => interopDefault(import('../pages/oma-tili/toivelistat/_id.vue' /* webpackChunkName: "pages/oma-tili/toivelistat/_id" */))
const _8d00d1b0 = () => interopDefault(import('../pages/ostoskori.vue' /* webpackChunkName: "pages/ostoskori" */))
const _299b14c7 = () => interopDefault(import('../pages/outlet/index.vue' /* webpackChunkName: "pages/outlet/index" */))
const _f787cbce = () => interopDefault(import('../pages/palaute.vue' /* webpackChunkName: "pages/palaute" */))
const _a8b60452 = () => interopDefault(import('../pages/sisustajan-joulukauppa/index.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/index" */))
const _c6ad54be = () => interopDefault(import('../pages/sisustusinspiraatiota/index.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/index" */))
const _952a20c8 = () => interopDefault(import('../pages/tarjoukset/index.vue' /* webpackChunkName: "pages/tarjoukset/index" */))
const _82ea3fd8 = () => interopDefault(import('../pages/tuotesarjat/index.vue' /* webpackChunkName: "pages/tuotesarjat/index" */))
const _67a1a0e9 = () => interopDefault(import('../pages/tuotteen-palautus.vue' /* webpackChunkName: "pages/tuotteen-palautus" */))
const _495a511d = () => interopDefault(import('../pages/unohditko-salasanasi.vue' /* webpackChunkName: "pages/unohditko-salasanasi" */))
const _0401e26b = () => interopDefault(import('../pages/uutiskirjeen-tilausvahvistus.vue' /* webpackChunkName: "pages/uutiskirjeen-tilausvahvistus" */))
const _4f685ace = () => interopDefault(import('../pages/uutuudet/index.vue' /* webpackChunkName: "pages/uutuudet/index" */))
const _49b1f075 = () => interopDefault(import('../pages/vahvista-sahkopostiosoitteesi.vue' /* webpackChunkName: "pages/vahvista-sahkopostiosoitteesi" */))
const _31cb8c3c = () => interopDefault(import('../pages/yhteistyo/index.vue' /* webpackChunkName: "pages/yhteistyo/index" */))
const _25747d47 = () => interopDefault(import('../pages/yrityksestamme/index.vue' /* webpackChunkName: "pages/yrityksestamme/index" */))
const _16fc3a4e = () => interopDefault(import('../pages/yritysmyynti/index.vue' /* webpackChunkName: "pages/yritysmyynti/index" */))
const _ea75339c = () => interopDefault(import('../pages/asiakaspalvelu/laskutus.vue' /* webpackChunkName: "pages/asiakaspalvelu/laskutus" */))
const _fd71dca4 = () => interopDefault(import('../pages/asiakaspalvelu/maksutavat/index.vue' /* webpackChunkName: "pages/asiakaspalvelu/maksutavat/index" */))
const _7dd56db2 = () => interopDefault(import('../pages/asiakaspalvelu/palautukset-ja-reklamaatiot.vue' /* webpackChunkName: "pages/asiakaspalvelu/palautukset-ja-reklamaatiot" */))
const _649ec881 = () => interopDefault(import('../pages/asiakaspalvelu/tietosuoja-rekisteriseloste.vue' /* webpackChunkName: "pages/asiakaspalvelu/tietosuoja-rekisteriseloste" */))
const _17d69088 = () => interopDefault(import('../pages/asiakaspalvelu/toimitustavat.vue' /* webpackChunkName: "pages/asiakaspalvelu/toimitustavat" */))
const _91e17f6a = () => interopDefault(import('../pages/asiakaspalvelu/verkkokaupan-ehdot.vue' /* webpackChunkName: "pages/asiakaspalvelu/verkkokaupan-ehdot" */))
const _613f3f8a = () => interopDefault(import('../pages/asiakaspalvelu/yhteystiedot.vue' /* webpackChunkName: "pages/asiakaspalvelu/yhteystiedot" */))
const _58fceda4 = () => interopDefault(import('../pages/sisustajan-joulukauppa/jouluinspiraatiota.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/jouluinspiraatiota" */))
const _1ea5988a = () => interopDefault(import('../pages/sisustajan-joulukauppa/joulukattaus.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joulukattaus" */))
const _de967c04 = () => interopDefault(import('../pages/sisustajan-joulukauppa/joulukattaus/index.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joulukattaus/index" */))
const _e203be94 = () => interopDefault(import('../pages/sisustajan-joulukauppa/joulukattaus/_slug.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joulukattaus/_slug" */))
const _9f32631e = () => interopDefault(import('../pages/sisustajan-joulukauppa/joululahjaideat.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joululahjaideat" */))
const _05fe0798 = () => interopDefault(import('../pages/sisustajan-joulukauppa/joululahjaideat/index.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joululahjaideat/index" */))
const _096b4a28 = () => interopDefault(import('../pages/sisustajan-joulukauppa/joululahjaideat/_slug.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joululahjaideat/_slug" */))
const _6ca97d56 = () => interopDefault(import('../pages/sisustajan-joulukauppa/joulusisustus.vue' /* webpackChunkName: "pages/sisustajan-joulukauppa/joulusisustus" */))
const _759f8e0c = () => interopDefault(import('../pages/sisustusinspiraatiota/jouluiset-sisustusideat.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/jouluiset-sisustusideat" */))
const _7ad78562 = () => interopDefault(import('../pages/sisustusinspiraatiota/kesamallisto.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/kesamallisto" */))
const _2ec43b69 = () => interopDefault(import('../pages/sisustusinspiraatiota/lastenhuone.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/lastenhuone" */))
const _2dbff7ca = () => interopDefault(import('../pages/sisustusinspiraatiota/live-shopping-x-nata-ja-linda.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/live-shopping-x-nata-ja-linda" */))
const _1cf66323 = () => interopDefault(import('../pages/sisustusinspiraatiota/makuuhuone.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/makuuhuone" */))
const _462b6fc8 = () => interopDefault(import('../pages/sisustusinspiraatiota/olohuone.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/olohuone" */))
const _34a2be8a = () => interopDefault(import('../pages/sisustusinspiraatiota/ruokailutila.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/ruokailutila" */))
const _a88c430a = () => interopDefault(import('../pages/sisustusinspiraatiota/sisustustyylit.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/sisustustyylit" */))
const _c6181f8c = () => interopDefault(import('../pages/sisustusinspiraatiota/syysmallisto.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/syysmallisto" */))
const _077e8320 = () => interopDefault(import('../pages/sisustusinspiraatiota/tyohuone.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/tyohuone" */))
const _0052607e = () => interopDefault(import('../pages/sisustusinspiraatiota/ulkotilat.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/ulkotilat" */))
const _88c2bc9e = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/index.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/index" */))
const _bf7c7882 = () => interopDefault(import('../pages/sisustusinspiraatiota/veke-x-juho-pasila.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/veke-x-juho-pasila" */))
const _3215cc78 = () => interopDefault(import('../pages/sisustusinspiraatiota/veke-x-linda-baki.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/veke-x-linda-baki" */))
const _5bb0f77b = () => interopDefault(import('../pages/sisustusinspiraatiota/veke-x-linda-viipuri.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/veke-x-linda-viipuri" */))
const _0272959f = () => interopDefault(import('../pages/sisustusinspiraatiota/veke-x-maru.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/veke-x-maru" */))
const _72018961 = () => interopDefault(import('../pages/sisustusinspiraatiota/veke-x-matias-ranua.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/veke-x-matias-ranua" */))
const _5e8457bd = () => interopDefault(import('../pages/sisustusinspiraatiota/veke-x-siru-pelttari.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/veke-x-siru-pelttari" */))
const _6f7e5806 = () => interopDefault(import('../pages/tarjoukset/aina-edulliset/index.vue' /* webpackChunkName: "pages/tarjoukset/aina-edulliset/index" */))
const _988f6c54 = () => interopDefault(import('../pages/tarjoukset/black-friday/index.vue' /* webpackChunkName: "pages/tarjoukset/black-friday/index" */))
const _0caa3019 = () => interopDefault(import('../pages/tarjoukset/joululahjatoive/index.vue' /* webpackChunkName: "pages/tarjoukset/joululahjatoive/index" */))
const _b744506a = () => interopDefault(import('../pages/tarjoukset/paivatarjous.vue' /* webpackChunkName: "pages/tarjoukset/paivatarjous" */))
const _243563fc = () => interopDefault(import('../pages/tarjoukset/synttarit-kilpailu.vue' /* webpackChunkName: "pages/tarjoukset/synttarit-kilpailu" */))
const _6c83a58a = () => interopDefault(import('../pages/tarjoukset/veke-x-anne-melender.vue' /* webpackChunkName: "pages/tarjoukset/veke-x-anne-melender" */))
const _684e4fea = () => interopDefault(import('../pages/tarjoukset/viikon-supertarjoukset.vue' /* webpackChunkName: "pages/tarjoukset/viikon-supertarjoukset" */))
const _1d2125d4 = () => interopDefault(import('../pages/yrityksestamme/arvot-lupaus.vue' /* webpackChunkName: "pages/yrityksestamme/arvot-lupaus" */))
const _923bc824 = () => interopDefault(import('../pages/yrityksestamme/asiakaskokemuksia.vue' /* webpackChunkName: "pages/yrityksestamme/asiakaskokemuksia" */))
const _0bb0be8a = () => interopDefault(import('../pages/yrityksestamme/meista.vue' /* webpackChunkName: "pages/yrityksestamme/meista" */))
const _3c8d2f76 = () => interopDefault(import('../pages/yrityksestamme/tyopaikat.vue' /* webpackChunkName: "pages/yrityksestamme/tyopaikat" */))
const _2569ed3a = () => interopDefault(import('../pages/yrityksestamme/veken-tarina.vue' /* webpackChunkName: "pages/yrityksestamme/veken-tarina" */))
const _0fe70271 = () => interopDefault(import('../pages/yritysmyynti/kodikkaat-toimistot-ja-tyohuoneet.vue' /* webpackChunkName: "pages/yritysmyynti/kodikkaat-toimistot-ja-tyohuoneet" */))
const _6b60ea78 = () => interopDefault(import('../pages/yritysmyynti/palvelut-airbnb-yrittajalle.vue' /* webpackChunkName: "pages/yritysmyynti/palvelut-airbnb-yrittajalle" */))
const _78ba4ffa = () => interopDefault(import('../pages/yritysmyynti/palvelut-majoitusyrittajalle.vue' /* webpackChunkName: "pages/yritysmyynti/palvelut-majoitusyrittajalle" */))
const _b7f5d33e = () => interopDefault(import('../pages/yritysmyynti/palvelut-sisustussuunnittelijalle.vue' /* webpackChunkName: "pages/yritysmyynti/palvelut-sisustussuunnittelijalle" */))
const _2b5f7b2c = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/byannab.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/byannab" */))
const _40c8b3ac = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/emskipihla.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/emskipihla" */))
const _7fa0d532 = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/itsetehtyunelma.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/itsetehtyunelma" */))
const _0cec0cc7 = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/janniamanda.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/janniamanda" */))
const _5ac9e8de = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/mahrotonmaja.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/mahrotonmaja" */))
const _17a464ac = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/natasalmela.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/natasalmela" */))
const _5406d4f3 = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/roosapalooza.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/roosapalooza" */))
const _3511a144 = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/saratickle.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/saratickle" */))
const _4a5e842f = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/suviliving.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/suviliving" */))
const _018b7b80 = () => interopDefault(import('../pages/sisustusinspiraatiota/vaikuttajien-suosikit/tiinayli.vue' /* webpackChunkName: "pages/sisustusinspiraatiota/vaikuttajien-suosikit/tiinayli" */))
const _505cf305 = () => interopDefault(import('../pages/tarjoukset/black-friday/osto-opas.vue' /* webpackChunkName: "pages/tarjoukset/black-friday/osto-opas" */))
const _17dd9676 = () => interopDefault(import('../pages/tarjoukset/black-friday/perjantain-shokkitarjoukset.vue' /* webpackChunkName: "pages/tarjoukset/black-friday/perjantain-shokkitarjoukset" */))
const _7ef03719 = () => interopDefault(import('../pages/tarjoukset/black-friday/ukk.vue' /* webpackChunkName: "pages/tarjoukset/black-friday/ukk" */))
const _6dc7b6be = () => interopDefault(import('../pages/tarjoukset/aina-edulliset/_slug.vue' /* webpackChunkName: "pages/tarjoukset/aina-edulliset/_slug" */))
const _9bfcaee4 = () => interopDefault(import('../pages/tarjoukset/black-friday/_slug.vue' /* webpackChunkName: "pages/tarjoukset/black-friday/_slug" */))
const _45fd374a = () => interopDefault(import('../pages/blogi/_slug.vue' /* webpackChunkName: "pages/blogi/_slug" */))
const _7dc30774 = () => interopDefault(import('../pages/brandit/_slug.vue' /* webpackChunkName: "pages/brandit/_slug" */))
const _6ec5e9d0 = () => interopDefault(import('../pages/content/_slug/index.vue' /* webpackChunkName: "pages/content/_slug/index" */))
const _381ba5bc = () => interopDefault(import('../pages/keskusvarastot/_slug.vue' /* webpackChunkName: "pages/keskusvarastot/_slug" */))
const _3da979d0 = () => interopDefault(import('../pages/myymalat/_slug.vue' /* webpackChunkName: "pages/myymalat/_slug" */))
const _27e4737f = () => interopDefault(import('../pages/outlet/_slug.vue' /* webpackChunkName: "pages/outlet/_slug" */))
const _2b6fc3fa = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _98976358 = () => interopDefault(import('../pages/tarjoukset/_slug.vue' /* webpackChunkName: "pages/tarjoukset/_slug" */))
const _86578268 = () => interopDefault(import('../pages/tuotesarjat/_slug.vue' /* webpackChunkName: "pages/tuotesarjat/_slug" */))
const _52d59d5e = () => interopDefault(import('../pages/uutuudet/_slug.vue' /* webpackChunkName: "pages/uutuudet/_slug" */))
const _7110b4c5 = () => interopDefault(import('../pages/category/_.vue' /* webpackChunkName: "pages/category/_" */))
const _4c30452a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2411f50a = () => interopDefault(import('../pages/_auth/verify/index.vue' /* webpackChunkName: "pages/_auth/verify/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/asiakaspalvelu",
    component: _5287695a,
    name: "asiakaspalvelu"
  }, {
    path: "/asiakastilin-edut",
    component: _f307424a,
    name: "asiakastilin-edut"
  }, {
    path: "/blogi",
    component: _428ff4ba,
    name: "blogi"
  }, {
    path: "/brandit",
    component: _7a55c4e4,
    name: "brandit"
  }, {
    path: "/category",
    component: _4d0b14b8,
    name: "category"
  }, {
    path: "/content",
    component: _5f1bc255,
    name: "content"
  }, {
    path: "/haku",
    component: _1833ebcc,
    name: "haku"
  }, {
    path: "/jaettu-ostoskori",
    component: _3ac574e0,
    name: "jaettu-ostoskori"
  }, {
    path: "/kassa",
    component: _27c2adf8,
    name: "kassa"
  }, {
    path: "/keskenerainen-ostoskori",
    component: _4f12f77c,
    name: "keskenerainen-ostoskori"
  }, {
    path: "/keskusvarastot",
    component: _34ae632c,
    name: "keskusvarastot"
  }, {
    path: "/kiitos",
    component: _7b83e3e4,
    name: "kiitos"
  }, {
    path: "/kilpailut-ja-arvonnat",
    component: _31f80c80,
    name: "kilpailut-ja-arvonnat"
  }, {
    path: "/kirjaudu",
    component: _e512795c,
    name: "kirjaudu"
  }, {
    path: "/liveshopping-kiitos-ilmoituksesta",
    component: _02148a1a,
    name: "liveshopping-kiitos-ilmoituksesta"
  }, {
    path: "/luo-tili",
    component: _b2c188e0,
    name: "luo-tili"
  }, {
    path: "/myymalat",
    component: _3f601b18,
    name: "myymalat"
  }, {
    path: "/oma-tili",
    component: _8aeb341a,
    children: [{
      path: "",
      component: _4ac16a36,
      name: "oma-tili"
    }, {
      path: "markkinointiluvat",
      component: _c7125844,
      name: "oma-tili-markkinointiluvat"
    }, {
      path: "omat-tiedot",
      component: _ea74374e,
      name: "oma-tili-omat-tiedot"
    }, {
      path: "tilaukset",
      component: _f2d4b9ee,
      name: "oma-tili-tilaukset"
    }, {
      path: "toivelistat",
      component: _c8e55916,
      name: "oma-tili-toivelistat"
    }, {
      path: "yhteenveto",
      component: _46ab8c1f,
      name: "oma-tili-yhteenveto"
    }, {
      path: "tilaukset/tilaus",
      component: _7e05ca64,
      name: "oma-tili-tilaukset-tilaus"
    }, {
      path: "tilaukset/tilaus/:slug",
      component: _7c4f291c,
      name: "oma-tili-tilaukset-tilaus-slug"
    }, {
      path: "toivelistat/:id",
      component: _dc58a1c6,
      name: "oma-tili-toivelistat-id"
    }]
  }, {
    path: "/ostoskori",
    component: _8d00d1b0,
    name: "ostoskori"
  }, {
    path: "/outlet",
    component: _299b14c7,
    name: "outlet"
  }, {
    path: "/palaute",
    component: _f787cbce,
    name: "palaute"
  }, {
    path: "/sisustajan-joulukauppa",
    component: _a8b60452,
    name: "sisustajan-joulukauppa"
  }, {
    path: "/sisustusinspiraatiota",
    component: _c6ad54be,
    name: "sisustusinspiraatiota"
  }, {
    path: "/tarjoukset",
    component: _952a20c8,
    name: "tarjoukset"
  }, {
    path: "/tuotesarjat",
    component: _82ea3fd8,
    name: "tuotesarjat"
  }, {
    path: "/tuotteen-palautus",
    component: _67a1a0e9,
    name: "tuotteen-palautus"
  }, {
    path: "/unohditko-salasanasi",
    component: _495a511d,
    name: "unohditko-salasanasi"
  }, {
    path: "/uutiskirjeen-tilausvahvistus",
    component: _0401e26b,
    name: "uutiskirjeen-tilausvahvistus"
  }, {
    path: "/uutuudet",
    component: _4f685ace,
    name: "uutuudet"
  }, {
    path: "/vahvista-sahkopostiosoitteesi",
    component: _49b1f075,
    name: "vahvista-sahkopostiosoitteesi"
  }, {
    path: "/yhteistyo",
    component: _31cb8c3c,
    name: "yhteistyo"
  }, {
    path: "/yrityksestamme",
    component: _25747d47,
    name: "yrityksestamme"
  }, {
    path: "/yritysmyynti",
    component: _16fc3a4e,
    name: "yritysmyynti"
  }, {
    path: "/asiakaspalvelu/laskutus",
    component: _ea75339c,
    name: "asiakaspalvelu-laskutus"
  }, {
    path: "/asiakaspalvelu/maksutavat",
    component: _fd71dca4,
    name: "asiakaspalvelu-maksutavat"
  }, {
    path: "/asiakaspalvelu/palautukset-ja-reklamaatiot",
    component: _7dd56db2,
    name: "asiakaspalvelu-palautukset-ja-reklamaatiot"
  }, {
    path: "/asiakaspalvelu/tietosuoja-rekisteriseloste",
    component: _649ec881,
    name: "asiakaspalvelu-tietosuoja-rekisteriseloste"
  }, {
    path: "/asiakaspalvelu/toimitustavat",
    component: _17d69088,
    name: "asiakaspalvelu-toimitustavat"
  }, {
    path: "/asiakaspalvelu/verkkokaupan-ehdot",
    component: _91e17f6a,
    name: "asiakaspalvelu-verkkokaupan-ehdot"
  }, {
    path: "/asiakaspalvelu/yhteystiedot",
    component: _613f3f8a,
    name: "asiakaspalvelu-yhteystiedot"
  }, {
    path: "/sisustajan-joulukauppa/jouluinspiraatiota",
    component: _58fceda4,
    name: "sisustajan-joulukauppa-jouluinspiraatiota"
  }, {
    path: "/sisustajan-joulukauppa/joulukattaus",
    component: _1ea5988a,
    children: [{
      path: "",
      component: _de967c04,
      name: "sisustajan-joulukauppa-joulukattaus"
    }, {
      path: ":slug",
      component: _e203be94,
      name: "sisustajan-joulukauppa-joulukattaus-slug"
    }]
  }, {
    path: "/sisustajan-joulukauppa/joululahjaideat",
    component: _9f32631e,
    children: [{
      path: "",
      component: _05fe0798,
      name: "sisustajan-joulukauppa-joululahjaideat"
    }, {
      path: ":slug",
      component: _096b4a28,
      name: "sisustajan-joulukauppa-joululahjaideat-slug"
    }]
  }, {
    path: "/sisustajan-joulukauppa/joulusisustus",
    component: _6ca97d56,
    name: "sisustajan-joulukauppa-joulusisustus"
  }, {
    path: "/sisustusinspiraatiota/jouluiset-sisustusideat",
    component: _759f8e0c,
    name: "sisustusinspiraatiota-jouluiset-sisustusideat"
  }, {
    path: "/sisustusinspiraatiota/kesamallisto",
    component: _7ad78562,
    name: "sisustusinspiraatiota-kesamallisto"
  }, {
    path: "/sisustusinspiraatiota/lastenhuone",
    component: _2ec43b69,
    name: "sisustusinspiraatiota-lastenhuone"
  }, {
    path: "/sisustusinspiraatiota/live-shopping-x-nata-ja-linda",
    component: _2dbff7ca,
    name: "sisustusinspiraatiota-live-shopping-x-nata-ja-linda"
  }, {
    path: "/sisustusinspiraatiota/makuuhuone",
    component: _1cf66323,
    name: "sisustusinspiraatiota-makuuhuone"
  }, {
    path: "/sisustusinspiraatiota/olohuone",
    component: _462b6fc8,
    name: "sisustusinspiraatiota-olohuone"
  }, {
    path: "/sisustusinspiraatiota/ruokailutila",
    component: _34a2be8a,
    name: "sisustusinspiraatiota-ruokailutila"
  }, {
    path: "/sisustusinspiraatiota/sisustustyylit",
    component: _a88c430a,
    name: "sisustusinspiraatiota-sisustustyylit"
  }, {
    path: "/sisustusinspiraatiota/syysmallisto",
    component: _c6181f8c,
    name: "sisustusinspiraatiota-syysmallisto"
  }, {
    path: "/sisustusinspiraatiota/tyohuone",
    component: _077e8320,
    name: "sisustusinspiraatiota-tyohuone"
  }, {
    path: "/sisustusinspiraatiota/ulkotilat",
    component: _0052607e,
    name: "sisustusinspiraatiota-ulkotilat"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit",
    component: _88c2bc9e,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit"
  }, {
    path: "/sisustusinspiraatiota/veke-x-juho-pasila",
    component: _bf7c7882,
    name: "sisustusinspiraatiota-veke-x-juho-pasila"
  }, {
    path: "/sisustusinspiraatiota/veke-x-linda-baki",
    component: _3215cc78,
    name: "sisustusinspiraatiota-veke-x-linda-baki"
  }, {
    path: "/sisustusinspiraatiota/veke-x-linda-viipuri",
    component: _5bb0f77b,
    name: "sisustusinspiraatiota-veke-x-linda-viipuri"
  }, {
    path: "/sisustusinspiraatiota/veke-x-maru",
    component: _0272959f,
    name: "sisustusinspiraatiota-veke-x-maru"
  }, {
    path: "/sisustusinspiraatiota/veke-x-matias-ranua",
    component: _72018961,
    name: "sisustusinspiraatiota-veke-x-matias-ranua"
  }, {
    path: "/sisustusinspiraatiota/veke-x-siru-pelttari",
    component: _5e8457bd,
    name: "sisustusinspiraatiota-veke-x-siru-pelttari"
  }, {
    path: "/tarjoukset/aina-edulliset",
    component: _6f7e5806,
    name: "tarjoukset-aina-edulliset"
  }, {
    path: "/tarjoukset/black-friday",
    component: _988f6c54,
    name: "tarjoukset-black-friday"
  }, {
    path: "/tarjoukset/joululahjatoive",
    component: _0caa3019,
    name: "tarjoukset-joululahjatoive"
  }, {
    path: "/tarjoukset/paivatarjous",
    component: _b744506a,
    name: "tarjoukset-paivatarjous"
  }, {
    path: "/tarjoukset/synttarit-kilpailu",
    component: _243563fc,
    name: "tarjoukset-synttarit-kilpailu"
  }, {
    path: "/tarjoukset/veke-x-anne-melender",
    component: _6c83a58a,
    name: "tarjoukset-veke-x-anne-melender"
  }, {
    path: "/tarjoukset/viikon-supertarjoukset",
    component: _684e4fea,
    name: "tarjoukset-viikon-supertarjoukset"
  }, {
    path: "/yrityksestamme/arvot-lupaus",
    component: _1d2125d4,
    name: "yrityksestamme-arvot-lupaus"
  }, {
    path: "/yrityksestamme/asiakaskokemuksia",
    component: _923bc824,
    name: "yrityksestamme-asiakaskokemuksia"
  }, {
    path: "/yrityksestamme/meista",
    component: _0bb0be8a,
    name: "yrityksestamme-meista"
  }, {
    path: "/yrityksestamme/tyopaikat",
    component: _3c8d2f76,
    name: "yrityksestamme-tyopaikat"
  }, {
    path: "/yrityksestamme/veken-tarina",
    component: _2569ed3a,
    name: "yrityksestamme-veken-tarina"
  }, {
    path: "/yritysmyynti/kodikkaat-toimistot-ja-tyohuoneet",
    component: _0fe70271,
    name: "yritysmyynti-kodikkaat-toimistot-ja-tyohuoneet"
  }, {
    path: "/yritysmyynti/palvelut-airbnb-yrittajalle",
    component: _6b60ea78,
    name: "yritysmyynti-palvelut-airbnb-yrittajalle"
  }, {
    path: "/yritysmyynti/palvelut-majoitusyrittajalle",
    component: _78ba4ffa,
    name: "yritysmyynti-palvelut-majoitusyrittajalle"
  }, {
    path: "/yritysmyynti/palvelut-sisustussuunnittelijalle",
    component: _b7f5d33e,
    name: "yritysmyynti-palvelut-sisustussuunnittelijalle"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/byannab",
    component: _2b5f7b2c,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-byannab"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/emskipihla",
    component: _40c8b3ac,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-emskipihla"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/itsetehtyunelma",
    component: _7fa0d532,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-itsetehtyunelma"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/janniamanda",
    component: _0cec0cc7,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-janniamanda"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/mahrotonmaja",
    component: _5ac9e8de,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-mahrotonmaja"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/natasalmela",
    component: _17a464ac,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-natasalmela"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/roosapalooza",
    component: _5406d4f3,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-roosapalooza"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/saratickle",
    component: _3511a144,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-saratickle"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/suviliving",
    component: _4a5e842f,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-suviliving"
  }, {
    path: "/sisustusinspiraatiota/vaikuttajien-suosikit/tiinayli",
    component: _018b7b80,
    name: "sisustusinspiraatiota-vaikuttajien-suosikit-tiinayli"
  }, {
    path: "/tarjoukset/black-friday/osto-opas",
    component: _505cf305,
    name: "tarjoukset-black-friday-osto-opas"
  }, {
    path: "/tarjoukset/black-friday/perjantain-shokkitarjoukset",
    component: _17dd9676,
    name: "tarjoukset-black-friday-perjantain-shokkitarjoukset"
  }, {
    path: "/tarjoukset/black-friday/ukk",
    component: _7ef03719,
    name: "tarjoukset-black-friday-ukk"
  }, {
    path: "/tarjoukset/aina-edulliset/:slug?",
    component: _6dc7b6be,
    name: "tarjoukset-aina-edulliset-slug"
  }, {
    path: "/tarjoukset/black-friday/:slug?",
    component: _9bfcaee4,
    name: "tarjoukset-black-friday-slug"
  }, {
    path: "/blogi/:slug",
    component: _45fd374a,
    name: "blogi-slug"
  }, {
    path: "/brandit/:slug",
    component: _7dc30774,
    name: "brandit-slug"
  }, {
    path: "/content/:slug",
    component: _6ec5e9d0,
    name: "content-slug"
  }, {
    path: "/keskusvarastot/:slug",
    component: _381ba5bc,
    name: "keskusvarastot-slug"
  }, {
    path: "/myymalat/:slug",
    component: _3da979d0,
    name: "myymalat-slug"
  }, {
    path: "/outlet/:slug",
    component: _27e4737f,
    name: "outlet-slug"
  }, {
    path: "/product/:slug?",
    component: _2b6fc3fa,
    name: "product-slug"
  }, {
    path: "/tarjoukset/:slug",
    component: _98976358,
    name: "tarjoukset-slug"
  }, {
    path: "/tuotesarjat/:slug",
    component: _86578268,
    name: "tuotesarjat-slug"
  }, {
    path: "/uutuudet/:slug",
    component: _52d59d5e,
    name: "uutuudet-slug"
  }, {
    path: "/category/*",
    component: _7110b4c5,
    name: "category-all"
  }, {
    path: "/",
    component: _4c30452a,
    name: "index"
  }, {
    path: "/:auth/verify",
    component: _2411f50a,
    name: "auth-verify"
  }],

  parseQuery: function(queryString) {
      return require('qs').parse(queryString);
    },
  stringifyQuery: function(object) {
      const queryString = require('qs').stringify(object);
      return queryString ? '?' + queryString : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
